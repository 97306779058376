import React from "react"
import Helmet from 'react-helmet'
import { Link, graphql } from 'gatsby'

import Layout from './layout'

const build_head_from = (pageContext, siteMetadata) => {
  const { baseUri, blogTitle } = siteMetadata

  const head = {
    title: `Tags - ${blogTitle}`,

    link: [
      { rel: "canonical", href: baseUri + pageContext.url },
    ],
  }

  return head
}

export default ({
  data,
  pageContext,
}) => {
  const { site } = data
  const { tags } = pageContext

  return <Layout>
    <Helmet {...build_head_from(pageContext, site.siteMetadata)} />

    <section className="posts">
      <header className="post-header">
        <h1 className="post-title">Tags</h1>
      </header>

      <ul className="pure-g post">
        { tags.map(tag =>
          <li key={tag.slug} className="pure-u-1-1 pure-u-lg-1-2">
            <i className="fa fa-fw fa-circle-o fa-sm light-icon mr-10" />
            <Link to={tag.url}>
              {tag.label}
            </Link> <small className="text-muted pull-right mr-10">{tag.count.toLocaleString()}</small>
          </li>
        ) }
      </ul>
    </section>
  </Layout>
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        blogTitle
        baseUri
      }
    }
  }
`
